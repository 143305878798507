import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { CloseOutlined,MenuOutlined } from "@ant-design/icons";
// @ts-expect-error TS(2307): Cannot find module assets/images/Colorlogo-nobackground.svg... Remove this comment to see the full error message
import fullLogo from "assets/images/Colorlogo-nobackground.svg";
import cn from "classnames";

import css from "./Header.module.scss";
import commonStyle from "styles/commonStyle/index.module.scss";
const HomePageHeader = () => {
  const [navExpanded, setNavExpanded] = useState(false);
  const [ismobile, setIsmobile] = useState<boolean>(false);

  // const {t} = useTranslation();
  const location = useLocation();

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    // const targetDiv = document.getElementsByClassName("fixed-top")[0];
    if (scrollPosition <= 30 && window.innerWidth >= 992) {
      // targetDiv?.classList?.add(css.homeBgTransparent);
    } else {
      // targetDiv?.classList?.remove(css.homeBgTransparent);
    }
    const navElements = document.querySelectorAll("nav a");
    // @ts-expect-error TS(2339): Property 'blur' does not exist on type 'Element'.
    navElements.forEach((element) => element.blur());
  };
  const justifyWindowWdith = () => {
    if (window.innerWidth >= 992) {
      setIsmobile(false);
    } else {
      setIsmobile(true);
    }
  };
  const resizeUpdate = () => {
    justifyWindowWdith();
    handleScroll();
    setNavExpanded(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", resizeUpdate);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", resizeUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    resizeUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <nav
        id="header-nav"
        className={cn(
          css.navWarp,
          "navbar navbar-expand-lg navbar-dark fixed-top"
        )}
        aria-label="Third navbar example"
      >
        <div className={cn(css.navContainer, "container")}>
          <Link className="navbar-brand" to="/">
            <img src={fullLogo} className={css.fullLogo} alt="fullLogo" />
          </Link>
          <div className={css.navRight}>
            {/* {ismobile && (
              <Link
                to="/contactUs"
                className={cn(
                  commonStyle.btn,
                  css.contactBtn,
                  css.mobileContactBtn
                )}
              >
                <span >Get Started</span>
              </Link>
            )} */}
            <button
              className={cn(css.menuBtn, "custom-toggler navbar-toggler")}
              type="button"
              aria-controls="navbarsExample03"
              aria-expanded={navExpanded}
              aria-label="Toggle navigation"
              onClick={() => setNavExpanded(!navExpanded)}
            >
              {!navExpanded?<MenuOutlined className={css.menuIcon}/>:<CloseOutlined className={css.menuIcon}/> }
            </button>
          </div>
          <div
            className={cn(css.mobileCollapse,`collapse navbar-collapse${navExpanded ?" show": ""}`)}
            id="navbarsExample03"
          >
            <ul className="navbar-nav ms-auto d-flex align-items-start align-items-lg-center justify-content-end w-100">
              {[
                // {
                //   name: "aboutUs",
                //   ref: `${process.env.REACT_APP_DOMAIN_URL}aboutUs`,
                //   text: "About Us",
                // } ,
                {
                  name: "home",
                  ref: `/`,
                  text: "Home",
                },
                {
                  name: "aboutUs",
                  ref: `/aboutUs`,
                  text: "About Us",
                },
                {
                  name: "services",
                  ref: "/services",
                  text: "Services",
                },
                {
                  name: "solutions",
                  ref: `/solutions`,
                  text: "Solutions & Technology",
                },
                {
                  name: "contactUs",
                  ref: "/contactUs",
                  text: "Contact Us",
                },
              ].map(({ name, ref, text }) => {
                return (
                  <li key={name} className={cn(css.navItem)}>
                    <Link
                      to={ref}
                      className={cn("nav-link", css.navigation)}
                    >
                      {text}
                    </Link>
                  </li>
                );
              })}
              {!ismobile && (
                <Link
                  to="/contactUs"
                  className={cn(
                    commonStyle.btn,
                    css.contactBtn
                  )}
                >
                  <span >Get Started</span>
                </Link>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HomePageHeader;
