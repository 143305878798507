import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import style from './LoadingCircle.module.scss'

export default function LoadingCircle() {
    return(
        <div className={style.spinLoading}>
            <Spin  indicator={<LoadingOutlined style={{color:'#223B55',fontSize:"46px"}}/>}/>
        </div>
    )
    
}