import React, { lazy, Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Auth } from "aws-amplify";
import axios from "axios";

import "./globals.d";

import GeneralLoader from "./components/generic/LoadingCircle";
import Layout from "./Layout/index";

import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
// const wssBaseURL = process.env.REACT_APP_WSS_BASE_URL;

const HomePage = lazy(() => import("./components/Pages/HomePage"));
const ServiceMain =lazy(() => import("./components/Pages/ServiceMain"));
const AboutUs=lazy(() => import("./components/Pages/AboutUs"));
const ContactUs = lazy(() => import("./components/Pages/ContactUs"));
const TechnologySolution = lazy(() => import("./components/Pages/TechnologySolution"));

const ErrorBoundary = lazy(
  () => import("./components/generic/error/ErrorBoundary")
);

const getToken = async () => {
  try {
    const accessToken = (await Auth.currentSession()).getAccessToken();    
    // @ts-expect-error TS(2554):
    const jwtToken = await accessToken?.jwtToken;
    return jwtToken;
  } catch (err) {
    console.log(err);
  }
};
const backToLogin = (response) => {
  window.location.replace("/");
};
// Interception error handling
const err = (error) => {
  const { response } = error;
  if (response) {
    if (response.status === 401) {
      // toast.error('Login timeout, please log in again');
      setTimeout(() => {
        backToLogin(response);
      }, 1000);
    } else if (response.status === 403) {
      toast.error("No permission");
      setTimeout(() => {
        backToLogin(response);
      }, 3000);
    } else {
      toast.error("Server error, please try again later");
    }
  }
  return Promise.reject(error);
};
// Add a request interceptor
axios.interceptors.request.use(async (config) => {
  const token = await getToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
}, err);

// Add a response interceptor
axios.interceptors.response.use((response) => {
  const res = response.data;
  if (res.code && ![0, 200].includes(res.code)) {
    toast.error(res.message);
    return Promise.reject(response);
  } else {
    return response;
  }
  // return response
}, err);

const App = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    changeLanguage("en");
    window.addEventListener("resize", resizeUpdate);
    return () => {
      window.removeEventListener("resize", resizeUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const resizeUpdate = (e) => {
    const width = e.target.innerWidth;
    windowInnerWidth(width);
  };

  const windowInnerWidth = (width) => {
    if (width > 767) {
      // setIsmobile(false)
    } else {
      // setIsmobile(true)
    }
  };
  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Suspense fallback={<GeneralLoader />}>
          <Routes>
            <Route
              /*exact*/
              path="/"
              element={
                <>
                  <Layout />
                </>
              }
            >
              <Route index path="" element={<HomePage />} />
              <Route index path="services" element={<ServiceMain />} />
x              <Route index path="aboutUs" element={<AboutUs />} />
              <Route index path="solutions" element={<TechnologySolution />} />
              <Route index path="contactUs" element={<ContactUs />} />
              
              <Route /*exact*/ path="*" element={<Navigate to="/home" />} />
            </Route>
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
        <ToastContainer autoClose={3000} />
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
